button{
  background: #424242;
  color: #fff !important;
  padding: 8px 10px;
  border-radius: 10px;
  border: none;
  margin: 10px 0px;
}
.metadata{
  margin-top: 15px;
  background: #F5F5F5;
  border: solid black 1px;
  padding: 20px;
}
