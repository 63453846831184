.colorholder{
    width: 95%;
    height: 220px;
    display: block;
    float: left;
    margin: 2.5%;
    box-sizing: border-box;
    border: 1px solid $black;
    display: flex;
    align-items: center;
    justify-content: center;
}
