section{
    padding: 30px 70px;
    z-index: 10;
    position: relative;
    padding-top: 30px;
    padding-bottom: 30px;
    background: white;
    overflow: hidden;
    box-sizing: border-box;

    .shadow{
        box-shadow: $sectionshadow;
    }
}
.content{
    max-width: $content-width;
    margin: 0 auto;
    overflow: hidden;
    position: relative;
}
.fourth{
    padding-left: 10px;
    width: 25%;
    float: left;
        min-height: 1px;

    box-sizing: border-box;
    &:first-child{
        padding-left: 0px;
    }
    &:last-child{
        padding-right: 0px;
    }
}
.third{
    padding: 15px 10px;
    width: calc(100%/3);
    float: left;
    box-sizing: border-box;
    &:first-child, &:nth-child(3n +1){
    padding: 15px 20px;
        padding-left: 0px;
    }
     &:nth-child(3n) {
         padding: 15px 20px;
        padding-right: 0px;
    }
}
.twenty{
    padding: 20px;
    float: left;
    width: 20%;
    box-sizing: border-box;
}
.sixty{
    padding: 20px;
    float: left;
    width: 60%;
    box-sizing: border-box;
}
.nopaddingtop{
    padding-top: 0;
}
.nomargintop{

        margin-top: 0;
}
.seventy{
    width: 80%;
}
.half{
    width: 50%;
    float: left;
    padding: 20px;
    box-sizing: border-box;
    &:first-child{
        padding-left: 0px;
    }
    &:last-child{
        padding-right: 0px;
    }
}
.body {
  position: relative;
  padding-bottom: 270px;
  min-height: 100vh;
  box-sizing: border-box;
}
@media screen and (max-width: $tablet){
    .body {
        padding-bottom: 345px;
    }
    section{
        padding: 30px;
    }
    .fourth{
        width: 50%;
        padding-left: 30px;
        padding-left: 30px;
        &:nth-child(2n+1) {
        padding-left: 30px;

        }
        &:nth-child(2n) {
        padding-left: 30px;
        }
    }
    .seventy, .twenty, .sixty{
      width: 100%;
    }
    .twenty{
          padding: 0px;

    }
    .half{
        width: 100%;
        padding-left: 0px;
        padding-right: 0px;
    }
    .third{
        width: 100%;
        padding: 15px 0px;
        &:first-child, &:nth-child(3n +1){
        padding: 15px 0px;
          padding-right: 0px;
          padding-left: 0px;
        }
        &:nth-child(3n) {
        padding: 15px 0px;
          padding-right: 0px;
          padding-left: 0px;
        }
    }
}
@media screen and (max-width: $phone){
  .body {
    padding-bottom: 0px;
  }
  .fourth{
      padding-left: 30px;
      width: 100%;
      &:nth-child(2n+1) {
      padding-left: 30px;
          padding-right: 0px;

      }
      &:nth-child(2n) {
      padding-left: 30px;
          padding-right: 0px;
      }
  }
}
