.noticeHolder{
    background: rgba(0, 0, 0, 0.38);
    position: fixed;
    width: 100%;
    height: 100%;
    top:0;
    left: 0;
    z-index: 10;

    .notice{
        background: black;
        width: 100px;
        height: 100px;
        position: absolute;
        margin-top: -50px;
        top: 50%;
        margin-left: -50px;
        border-radius: 10px;
        left: 50%;
        .icon{
            margin: 20px auto 8px;
            img{
                width: 47px;
                margin: 0 auto;
                display: block;
            }
        }
        .message{
            text-align: center;
            color: $white;
        }
    }
}
