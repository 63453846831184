h1, h2, h3, h4, h5, h6, p, span, div, label{
    font-family: 'Lato', sans-serif;
    font-display: swap;
}
label{
    font-weight: $light;
}
h1, h2, h3, h4, h5, h6{
    font-weight: $light;
    margin-top: 0px;
    margin-bottom: 20px;
}
time {
    font-size: $font20;
    font-weight: $light;
}
h1{
    font-size: $font50
}
h2{
    font-size: $font40
}
h3{
    font-size: $font35
}
h4{
    font-size: $font25
}
h5{
    font-size: $font20
}
h6{
    font-size: $font15
}
p, .text{
    font-weight: $light;
    font-size: $text;
    line-height: $text-lineheight;
    letter-spacing: 0.7px;
    a, a span{
      color: #546E7A;
      border-bottom: 1px solid;
    }
}
@media screen and (max-width: $phone){
h1{
    font-size: $font40
}
h2{
    font-size: $font30
}
}
