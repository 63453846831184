.loading{
    img{
        animation: rotation 2s infinite linear;
        position: fixed;
        left: 50%;
        top: 50%;
        margin-top: -30px;
        margin-left: -30px;
    }
}

@-webkit-keyframes rotation {
    from {
        transform: rotate(0deg);
    }
    to {
        transform: rotate(359deg);
    }

}
