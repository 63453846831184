.RichEditor-controls {
  border: 1px solid black;
  display: inline-block;
  width: 100%;
  max-width: 100%;
  box-sizing: border-box;
  overflow: hidden;
  .RichEditor-styleButton{
    border-right: 1px solid black;
    padding: 8px 8px;
    display: inline-block;
    border-bottom: none;
    border-top: none;
    cursor: pointer;
    &:hover{
     background: $light-grey;
      color: $white;
    }
    &.RichEditor-activeButton{
      background: $grey;
      color: $white;
    }
  }
}
