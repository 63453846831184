button{
  cursor: pointer;
}
.uploadimgform{
  position: absolute;
  background: #ffffff;
  width: 100%;
  padding: 15px;
  box-sizing: border-box;
  top: 0;
  left: 0;
  bottom: 0;
  input[type="file"]{
    background: $white;
    padding-left: 0px;
  }
  button {
    margin-right: 15px;
  }
}
.uploadimgbtn{
  clear: both;
  position: absolute;
  bottom: 20px;
  right: 30px;
}
.imagesCollection{
  overflow: hidden;
  height: 91%;
  overflow-y: scroll;
}
.imagePicker{
  position: relative;
  &.close{
    .imageHolder{
      display: none;
    }
  }
  .imageHolder{
    .header{
      background: $grey;
      margin-left: -10px;
      margin-right: -10px;
      margin-top: -10px;
      margin-bottom: 10px;
      padding: 5px 15px;
      overflow: hidden;
      span{
        float: right;
        color: $white;
        cursor: pointer;
      }
    }
    box-shadow: 0px 0px 39px 0px rgba(0,0,0,0.75);
    background: white;
    padding: 10px;
    border: solid 1px grey;
    display: block;
    position: fixed;
    clear:both;
    overflow: hidden;
    width: 50%;
    max-width: 550px;
    height: 50%;
    box-sizing: border-box;
    left: 0;
    right: 0;
    margin: auto;
    top: 0;
    bottom: 0;
    .image{
      cursor: pointer;
      box-sizing: border-box;
      max-width: 18%;
      margin: 1%;
      float: left;
      width: 100px;
      overflow: hidden;
      height: 100px;
      border: solid 4px transparent;
      float: left;
      &:hover{

        border-width: 2px;
      }
      &.selected{
        border-color: $blue;
      }
      &.primary{
        border-color: $red;
      }
      &.previewImg{
        border-color: green;
      }
      img{
        object-fit: cover;
        width: 100px;
        height: 100px;
      }
    }
  }
}
