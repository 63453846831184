.underline{
    border-bottom: 1px solid $black;
    padding-bottom: 5px;
}
.underlineSection{
  border-bottom: solid 1px;
}
.centertext{
    text-align: center
}
.nomarginbottom{
    margin-bottom: 0;
}
.smallmarginright{
  margin-right: 10px;
}
.borderd{
    border: 1px solid $black;
}
.flexcenter{
    display: flex;
    align-items: center;
    justify-content: center;
}
.firstSection{
    padding-top: 60px;
}
.fullheightSection{
    min-height: 100vh;
}
.success{
    color: #41a745;
}
.error{
    color: #E91E63;
}
.hide{
    display: none;
}
.space{
    height: 40px;
    display: block;
    clear: both;
    width: 100%;
    &.big{
        height: 60px;
    }
    &.bigger{
        height: 80px;
    }
    &.small{
        height: 20px;
    }
}
