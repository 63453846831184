.bgvideoSection{
    height: 100vh;
    position: relative;
    background: transparent;
}
.bgvideoholder{
    position: fixed;
    height: 100vh;
    top: 0;
    left: 0;
    width: 100%;
    z-index: -100;
    background: url(/bg.jpg);
    background-size: cover;
    background-position: bottom right ;
}
.overlay{
    position: fixed;
    background: rgba(0, 0, 0, 0.45);
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    z-index: -99;
    height: 120vh;
    margin-top: -10vh;
}
.bgvideo{
    position: fixed;
    right: 0;
    bottom: 0;
    z-index: -100;
}
