a, button, a span{
  font-size: $text;
  line-height: $text-lineheight;
  color: $black;
  font-weight: $light;
  text-decoration: none;
  &:hover{
    color: $grey;
  }
  &.btn{
    cursor: pointer;
    text-decoration: none;
    background-color: $black;
    color: #fff;
    padding: 10px 20px;
    margin: 5px 0;
    overflow: hidden;
    display: inline-block;
    border-color: $black;
    border-style: solid;
    border-width: 2px;
    border-radius: 0px;
    transition: all 300ms ease;
    transition-property: border-color, color, border-color;
    &:hover {
      background-color: $white;
      border-color: $black;
      color: $black;
    }
    &.small{
      font-size: 18px;
      padding: 4px 8px;
    }
    &.light{
      background-color: $white;
      border-color: $black;
      font-weight: 400;
      color: $black;
      &:hover {
        background-color: $black;
        border-color: $white;
        color: $white;
      }
    }
  }
}
