textarea, input{
    width: 100%;
    display: inline-block;
    margin: 0;
    padding: 0;
    box-sizing: border-box;
    max-width: 500px;
    line-height: 35px;
    font-size: 20px;
    padding: 0 10px;
    margin-bottom: 12px;
    border: solid $grey 1px;
    font-size: $font20;
    resize: none;
    clear: both;
    display: block;
    font-weight: $light;
}
label{
    font-size: $font25;
    display: inline-block;
    margin-bottom: 10px;
}
#loginform{
  input[type="submit"]{
    margin-top: 10px;
  }
}
