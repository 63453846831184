footer {
  background: $dark_grey;
  overflow: hidden;
  padding: 30px 0px;
  z-index: 10;
  position: relative;
  padding-left: 30px;
  padding-right: 30px;
  position:absolute;
  bottom:0;
  width:100%;
  min-height: 270px;
  background: #000;
  box-sizing: border-box;
  .socialLinks{
    a{
      max-width: 30px;
      color: $white;
      float: none;
    }
  }
  p, .email{
    color: $white;
    margin-top: 0px;
    padding-bottom: 0px;
    margin-bottom: 0;
    font-size: 16px;
  }

  ul{
    list-style-type: none;
    padding-left: 0px;
    li{
      line-height: 30px;
      a{
        color: $white;
        font-size: 18px;
        border-color: $white;
      }
    }
  }
  h1, h2,h3,h4,h5,h6{
    color: $white
  }
}
@media screen and (max-width: $smallscreen){
  footer .email, footer p {
    color: #fff;
    margin-top: 0;
    padding-bottom: 0;
    margin-bottom: 0;
    font-size: 14px;
  }
}
@media screen and (max-width: $phone){
  footer {
    position:relative;
  }
}
