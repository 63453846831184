.consentbanner {
  position: fixed;
  background: white;
  z-index: 100;
  width: 100%;
  bottom: 0;
  padding: 20px;
  box-sizing: border-box;
  -webkit-box-shadow: 0 9px 36px 0px rgba(153,153,153,1);
  -moz-box-shadow: 0 9px 36px 0px rgba(153,153,153,1);
  box-shadow: 0 9px 36px 0px rgba(153,153,153,1);
  p{
    font-size: 16px;
    width: 70%;
    float: left;
  }
  h5{
    margin-bottom: 0px;
    font-weight: 600;
  }
  p{
    margin-bottom: 0px;
    margin-top: 10px;
  }
  .btn{
    float: right;
    margin-left: 10px;
    border: solid $black 2px;
    padding:  4px 8px;
    cursor:  pointer;
    transition: all 300ms ease;
    transition-property: border-color, color, border-color;
    &:hover {
      background-color: $black;
      color: $white;
    }
    &.dark{
      background-color: $black;
      color: $white;
      &:hover {
        background-color: $white;
        color: $black;
      }
    }
  }
  .btnholder{
    position: absolute;
    bottom: 20px;
    right: 20px;
  }
}
@media screen and (max-width: $tablet){

  .consentbanner {
    p {
      width: 100%;
      margin-bottom: 10px;
    }
    .btnholder{
      position: relative;
      float: right;
      bottom: 0;
      right: 0;
    }
  }
}
