/* Text colors */

$black: #000000;
$dark-grey: #333333;
$light-grey: #999999;
$dark_grey: #1d1d1d;
$grey: #666666;
$red: #C62828;
$blue: blue;
$white: #ffffff;
$sectionshadow: 0px -12px 20px 1px #0000002e;
