.projects, .images, {
    .third{
        padding: 7px 5px;
        float: left;
        box-sizing: border-box;
        position: relative;
        overflow: hidden;
        border: 3px solid #fff;
        &:first-child, &:nth-child(3n +1){
            clear:left;
            padding: 8px 10px;
            padding-left: 0px;
        }
        &:nth-child(3n){
            padding: 8px 10px;
            padding-right: 0px;
        }

        img {
            width: 100%;
            max-width: 350px;
        }
    }
    .project{
        cursor: pointer;
                transition: border-width 0.1s;
            &:hover{
                border-width:10px;
            }
            &:active{
                border-width:20px;
            }
        a{
            display: inline;
            border: none;
            img{
                display: block;
                transition: transform 0.1s;
            }
            // &:hover{
            //     img{
            //         transform:translate(-50%,-50%) scale(0.95) translate3d(0, 0, 0);
            //         -webkit-backface-visibility: hidden;
            //     }
            // }
            // &:active{
            //     img{
            //         transform: scale(0.92) translate3d(0, 0, 0);
            //         -webkit-backface-visibility: hidden;
            //     }
            // }
        }
    }
}
.projectImages{
  img{
    max-width: 100%;
    cursor: pointer;
  }
}
@media screen and (max-width: $tablet){
    .projects, .images{
        .third{
            padding-left: 0px !important;
            padding-right: 0px !important;
            &:first-child, &:nth-child(3n +1){
                padding: 7px 10px;
                padding-left: 0px;
            }
            &:nth-child(3n){
                padding: 7px 10px;
                padding-right: 0px;
            }
        }
    }
}
