/*font-weight*/
$light: 300;
$regular: 400;
$bold: 700;


/* font-size*/

$font60: 60px;
$font50: 50px;
$font40: 40px;
$font35: 35px;
$font30: 30px;
$font25: 25px;
$font20: 20px;
$font15: 15px;


$text: 22px;
$text-lineheight: 28px;
