.socialLinks{
    a{
        text-decoration: none;
        border-bottom: none;
        max-width: 50px;
        margin-right: 10px;
        display: inline-block;
        float: left;
        width: 100%;
        opacity: 1;
        transition: opacity 0.3s;
        &:hover{
            opacity: 0.7;
        }
    }
}
