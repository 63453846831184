.logoHolder{
    color: $white;
        position: fixed;
        z-index: -90;
        width: 100%;
    #logo {
        width: 200px;
        max-width: 40%;
        margin-bottom: $margin3;
    }
    h1{
        margin-bottom: 0;
        margin-bottom: 10px;
    }
    h2, h3{
        font-size: $font30;
    }
}

