.preview{
    &::before {
        content: '';
        display: block;
    }
    img{
        position: fixed;
        top: 50%;
        left: 50%;
        max-width: 80%;
        max-height: 98%;
        transform: translate(-50%, -50%);
    }
    &.true {
        display: block;
    }
    .fullsize{

        background: $white;
    }

    display: none;
    background: rgba(0, 0, 0, 0.7);
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
}

.previmg{
    display: block;
    border-left: 0;
    position: fixed;
    top: 50%;
    left: 10px;
    z-index: 100;
    cursor: pointer;
    width: 40px;
    height: 34px;
    margin-left: 0px;
    img{
      transition: all .1s ease-in-out;
      position: relative;
    }
    &:hover {
      img{
        margin-left: -5px;
      }
    }
    &:active {
      img{
        margin-left: -10px;
      }
    }
}
.nextimg{
    display: block;
    border-right: 0;
    width: 0px;
    height: 0px;
    position: fixed;
    top: 50%;
    right: 10px;
    z-index: 100;
    cursor: pointer;
    width: 40px;
    height: 34px;
    margin-left: 0px;
    img{
      transition: all .1s ease-in-out;
      position: relative;
    }
    &:hover {
      img{
        margin-left: 5px;
      }
    }
    &:active {
      img{
        margin-left: 10px;
      }
    }
}
.projectImagesHolder{
  div:nth-child(3n+1) {
      clear: left;
  }
}
.projectPreview{
    .half{
        &:nth-child(1) {
            a{
                border: none;
                right: 20px;
                overflow: hidden;
                display:block;
                img{
                    transform: scale(1) translate3d(0, 0, 0);
                    -webkit-backface-visibility: hidden;
                    transition: transform 0.1s;
                    display: block;
                    z-index: 10;
                    position: relative;
                }
                &:hover{
                    img{
                        transform: scale(0.95) translate3d(0, 0, 0);
                        -webkit-backface-visibility: hidden;
                    }
                }
            }
        }
        &:nth-child(2){
            right: 0;
            top: 0;
            h3{
                padding-bottom:5px;
                border-bottom: 1px solid $black
            }
            p{
                padding-bottom: 30px;
            }
            a{
                float: right;
                position: absolute;
                bottom: 20px;
                right: 0px;
                &:after{
                    content: '';
                }
            }
        }
    }
}
@media screen and (max-width: 1000px){
    .projectPreview{
        max-width: 500px;

        .half{
            width: 100%;
            padding-left: 0px;
            padding-right: 0px;
            &:nth-child(1) {
                padding-bottom: 0px;
                a:hover{
                    img{
                        transform: scale(1) translate3d(0, 0, 0);
                        -webkit-backface-visibility: hidden;
                    }
                }
            }
            &:nth-child(2) {
                right: 0;
                top: 0;
                border: 1px solid #000000;
                padding: 20px;
                border-top: 0;
                h3{
                    border-bottom: none;
                }
                a{
                    right: 20px;
                }
            }
        }
    }
}
@media screen and (max-width: 750px) {
  .projects .project{
    text-align: center;
    a{
      display: inline-block;
      border: none;
      overflow: hidden;
      margin: 0 auto;
    }
  }
}
