.black{
    background: $black;
}
.dark-grey{
    background: $dark-grey;
}
.white{
    background: $white;
}
.whitewhiteText{
    color: $white;
}
