.menuToggle{
    position: fixed;
    right: 15px;
    top: 8px;
    transition: top 0.1s;
    cursor: pointer;
    z-index: 100;
    .bar{
        width: 35px;
        height: 2px;
        background-color: #fff;
        margin: 9px 0;
    }
    &.blackmenu{
        .bar{
            background-color: #000;
        }
    }
    &:hover{
        top: 6px;
        .bar{
            margin: 10px 0;
        }

    }
}
.menuOpend{
    transition: top 0.1s;
    .bar{
        transition: margin 0.1s;
    }
}
.menuOpen .menu, .menuOpend .menu{
    transition: margin $menu-slide-time;
}
.menu{
    background: $dark-grey;
    z-index: 90;
    height: 100vh;
    color: white;
    top: 0;
    right: 0;
    margin-right: -300px;
    position: fixed;
    width: 300px;

    ul{
        margin-top: 60px;
        list-style-type:none;
        li{
            font-weight: 100;
            a{
                font-size: 25px;
                line-height: 40px;
                color: $white;
                border-color: $white;
            }
        }
    }
}

.closeMenuLayer{
    visibility: hidden;
    position: fixed;
    opacity: 0;
    left: 0;
    top: 0;
    width: 100%;
    height: 100%;
    z-index: 100;
    background: rgba(0, 0, 0, 0.09);
    margin-left: 0px;
    transition: visibility 0s, opacity $menu-slide-time, margin $menu-slide-time;
}









.body{
    transition: margin $menu-slide-time, padding $menu-slide-time;
    overscroll-behavior-y: none;
    &.menuOpen{
        margin-left: -300px;
        padding-right: 300px;
    }
}
.bgvideo{
    transition: right $menu-slide-time;
}
.menuOpen{
    .closeMenuLayer{
        opacity: 1;
        visibility: visible;
        margin-left: -300px;
    }
    .menuToggle{
        top: 0px;
        height: 50px;
        width: 35px;
        .bar{
            margin: 9px 0;

            transform: rotate(45deg);
            top: 20px;
            position: absolute;
            right: 0;
            &:last-child{
                transform: rotate(-45deg);
            }
        }

        &.blackmenu .bar{
            background-color: $white;
        }
    }
    .menu{
        margin-right: 0px;
    }
    .bgvideo{
        right: 300px;
    }
}
